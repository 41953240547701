//Variables
$primary: #203f87;
$white: #ffff;
$aqua: #55caf0;
$aquatext: #85c1d5;
// $disabledGrey:#C3C3C3;
$disabledGrey: #e4e4e4;
$danger: #dc3546;
$success: green;

// ====================================MIXIN=================================

// Media queries
$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xlg: 1500px,
);

//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

.h100vh {
  height: 100vh;
  @include respond-below(sm) {
    height: 100%;
  }
}

.w100 {
  width: 100%;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.app_container {
  min-height: 100vh;
}
* {
  line-height: 1.5;
}
.table td,
.table th {
  padding: 0.5rem;
}

.navigation_conainer {
  @include respond-below(sm) {
    padding-left: 15px;
    padding-right: 15px;

    position: fixed;
    z-index: 99999;
  }

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: $primary;
  align-items: center;
  flex: 1;
  padding-left: 0;
  padding-right: 0;

  .flex4 {
    flex: 0.4;
    width: 100%;
    height: 100%;
    @include respond-below(sm) {
      display: none;
    }
  }
  .flex6 {
    flex: 0.6;
    width: 100%;
    height: 100%;
    @include respond-below(sm) {
      display: none;
    }
  }
  .menu_icon {
    font-size: 1.5rem;
    color: $white;
  }
  .user_details {
    text-align: center;
    margin-bottom: 20px;
  }
  .navLogo {
    width: 120px;
    border-radius: 50%;
    object-fit: cover;
    height: 120px;
    @include respond-below(sm) {
      height: 40px;
      width: 40px;
    }
  }
  .flex1 {
    flex: 1;
  }
  .today_date {
    color: $white;
    font-size: 0.75rem;
  }
  .mobile_nav {
    display: none;
    @include respond-below(sm) {
      display: flex !important ;
      height: 60px;
      justify-content: space-between;
      align-items: center;
    }
  }
  @include respond-below(sm) {
    height: 60px;
  }
}
.login_page {
  height: 100vh;
}

.modal-spacing {
  margin: 3rem !important;
  padding: 3rem !important;
  @include respond-below(lg) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  @include respond-below(sm) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.Snackbar_snackbar-wrapper__ocbPJ {
  z-index: 2000 !important;
}

.Snackbar_snackbar__text__1Hx2a {
  white-space: initial;
}

.login_page__logo {
  align-self: center;
  justify-content: center;
  display: flex;
  margin-bottom: 100px;
  @include respond-below(sm) {
    display: none;
  }
}
input[data-readonly] {
  pointer-events: none;
}
.login_page__form {
  background-color: $primary;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.waiting-input-block {
  @include respond-below(md) {
    margin-bottom: 10px;
  }
}

.logotype {
  max-width: 15vw;
  &.mobileLogo {
    display: none;
    @include respond-below(sm) {
      max-width: 100%;
      display: flex !important;
      padding-top: 2rem;
      padding-bottom: 2rem;
      justify-content: center;
      img {
        border-radius: 5%;
        max-width: 45vw;
      }
    }
  }
}

.removeRef {
  .btn {
    font-size: 13px;
    justify-content: center !important;
  }
  .red {
    color: $danger;
  }
  display: flex;
}

.controlled-prescription-table {
  .table {
    thead {
      th {
        width: 15% !important;
      }
    }
    td {
      vertical-align: middle !important;
    }
  }
  .form-group {
    margin-bottom: 0 !important;
  }
}

.search-icon {
  position: absolute;
  top: 50%;
  z-index: 2;
  transform: translate(0, -50%);
  right: 15px;
  left: 15px;
  cursor: pointer;
  height: 96%;
  min-width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 5px 5px 0;
  display: flex;
  justify-content: flex-end;
  &:hover {
    transition: 300ms;
    background-color: rgba(0, 123, 255, 0.25);
  }
  i {
    color: $primary;
    padding-right: 15px;
  }
}

.tabs {
  justify-content: center;
  .btn-link {
    margin-bottom: 5px;
    color: $white;
    cursor: pointer;
    display: block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1.2rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    &:hover,
    &:focus {
      text-decoration: none;
    }
    i {
      color: $white;
      margin-right: 0.5rem;
      width: 20px;
    }
  }
}

#mySidenav {
  padding-top: 60px;
  * {
    color: $white;
  }
  i {
    width: 25px;
  }
}
.px-5rem {
  padding-left: 3rem;
  padding-right: 3rem;
}
.page_header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 110px;
  i {
    color: $primary;
    margin-right: 0.5rem;
  }
}
.filter-block {
  &__patient-list {
    position: absolute;
    z-index: 2;
  }
  ul {
    max-height: 43vh;
    overflow: auto;
    margin-top: -10px;
    border-radius: 0;
  }
}

.btn-link {
  color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

a {
  color: black;
}
a:hover {
  text-decoration: none;
}
.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
  &:hover,
  &:focus {
    border-color: $primary !important;
  }
}
.btn-outline-primary {
  color: $primary !important;
  border-color: $primary !important;
  &:hover,
  &:focus {
    border-color: $primary !important;
    color: $white !important;
    background-color: $primary !important;
  }
}
.buttons_container {
  .btn-primary {
    font-size: 1.3rem;
  }
}

.textGrey {
  color: grey;
}
.textPrimary {
  color: $primary;
}
.card {
  box-shadow: 0 3px 15px -2px #dfdfdf;
  margin-bottom: 10px;
  border-radius: 10px;
}
.card-body {
  padding: 0.85rem;
}
.fc-toolbar-title {
  font-size: 1.5rem !important;
}
.waitingHighlights {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  @include respond-below(sm) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.payment-section {
  &__patient-list {
    position: absolute;
    z-index: 2;
    ul {
      max-height: 43vh;
      overflow: auto;
      margin-top: -10px;
      border-radius: 0;
    }
  }
  .card {
    border: 1px solid rgb(126, 126, 126);
  }

  .payment-card {
    min-width: 1310px;
    @include respond-below(sm) {
      margin-left: -15px;
    }
  }
}

.overflow-scroll {
  overflow-x: scroll;
}

.settings_section {
  .fullPayment {
    margin-bottom: 1rem;
    .input-group {
      width: 80px;
      margin-left: 0.8rem;
    }
  }
}
.search_patient {
  .search_result {
    height: 40vh;
    span {
      display: block;
    }
  }
  .btn-primary {
    font-size: 0.875rem !important;
    padding: 0.75rem 2rem;
  }
  a,
  input {
    font-size: 14px;
  }
}
.font-size-sm {
  font-size: 0.875rem;
}
.react-datepicker__header,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: $primary;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: $primary;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name {
  color: #fff;
}
.react-datepicker-popper {
  z-index: 3;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #203f87;
}
.react-datepicker {
  display: flex;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.pointer {
  cursor: pointer;
}
.right_container {
  max-height: 100vh;
  overflow: overlay;
  height: auto;
  @include respond-below(sm) {
    // max-height: 100% !important;
    padding-bottom: 100px;
    height: 100% !important;
    padding-top: 60px;
  }
}

.btn {
  border-radius: 0.5rem;
}
.btn-lg {
  font-size: 1rem;
  margin-bottom: 15px;
  min-width: 240px;
}
.fc .fc-non-business,
.fc-day-disabled {
  background: $disabledGrey !important;
}
.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0 !important;
}
.fc-v-event {
  background: $white;
  border: 1px solid $white;
}
.patient_table_row {
  .col {
    padding: 5px;
  }
}
.spinner-block {
  &__elem {
    background-color: $primary;
  }
  min-height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.btn:focus {
  box-shadow: none !important;
}
.login_form__button {
  min-width: 160px;
  margin-top: 40px;
  background: $aqua !important;
}

.colorAqua {
  color: $aquatext !important;
}

.btn-sm {
  padding: 0.5rem 1.3rem;
  font-size: 0.875rem;
  width: 85px;
}
.btn-md {
  padding: 0.5rem 1.3rem;
  font-size: 0.875rem;
  width: 240px;
}
.btn-primary {
  -webkit-box-shadow: 0px 0px 2px 1px rgba(32, 63, 135, 0.6);
  -moz-box-shadow: 0px 0px 2px 1px rgba(32, 63, 135, 0.6);
  box-shadow: 0px 0px 2px 1px rgba(32, 63, 135, 0.6);
}
.active {
  transform: scale(1.01);
  color: $primary;
  backface-visibility: inherit;
}
.patient__block {
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 55vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1.5rem;
  ul {
    overflow: auto;
  }
  ul li {
    font-size: 15px;
    cursor: pointer;
    margin: 10px 5px;
    transition: 100ms;
    display: block;
    &:hover {
      transform: scale(1.01);
      color: $primary;
      backface-visibility: inherit;
    }
  }
}
.doctor_session {
  display: block;
  color: $primary;
  text-align: left;
}
.form__action {
  font-style: italic;
  color: $primary;
  cursor: pointer;
}
.patient_app_container {
  max-height: 70vh;
  overflow: auto;
}
.thinScrollBar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #cfd1ce;
  background-color: #cfd1ce;
}

.thinScrollBar::-webkit-scrollbar {
  width: 0.1875rem !important;
  background-color: #cfd1ce;
}

.thinScrollBar::-webkit-scrollbar-thumb {
  background-color: #aeb4be;
  border: 3px solid #aeb4be;
}

.selectedSlot {
  background: $primary !important;
  border-color: $primary !important;
  div {
    color: $white !important;
  }
}

.danger-color {
  color: $danger;
  &:hover,
  &:active,
  &:focus {
    color: $danger;
  }
}
.success-color {
  color: $success !important;
  &:hover,
  &:active,
  &:focus {
    color: $success !important;
  }
}
.form__search_list {
  position: absolute !important;
  z-index: 2;
  ul {
    max-height: 43vh !important;
    overflow: auto !important;
    margin-top: -16px !important;
    border-radius: 0;
    li {
      line-height: 1;
      cursor: pointer;
    }
  }
}
.forgot_password {
  color: $white;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  &:hover {
    color: $white;
  }
}

.fc-timegrid-slot-lane {
  background: rgb(238, 238, 238);
  pointer-events: none !important;
}
.dark_link {
  &:hover {
    color: black;
  }
}
.grey-text {
  color: grey !important;
}

.form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #ced4da !important;
}

.grey-text {
  color: grey !important;
}
.page-item.active .page-link {
  background: $primary;
  border-color: $primary;
}
.page-link {
  color: $primary;
  &:hover {
    color: $primary;
  }
  &:focus {
    box-shadow: none;
  }
}
// .modal-dialog {
//   max-width: 700px !important;
// }

.h100 {
  height: 100%;
}

.button_block {
  margin-top: 1.5rem;
  width: 100%;
  @include respond-below(xs) {
    width: auto !important;
    margin-top: 0;
  }
  button {
    min-height: 38px;
    height: auto;
    width: 240px;
  }
}
.capitalize {
  text-transform: capitalize;
}
.w100 {
  width: 100%;
}
.modal-90w {
  @include respond-below(xs) {
    margin: 0 auto;
    width: 80vw !important;
  }
  width: 60vw !important;
  max-width: unset !important;
}
.waiting_table {
  max-width: 80%;
  @include respond-below(md) {
    max-width: 100%;
  }
}
.react-datepicker-wrapper {
  width: 100%;
}
.submitPayment {
  padding-bottom: 2rem;
}
.nav-tabs .nav-item {
  @include respond-below(xs) {
    margin-bottom: 0px;
    flex-grow: 1;
    white-space: nowrap;
  }
}
.nav-link {
  @include respond-below(xs) {
    padding: 0;
  }
}
.nav-tabs {
  @include respond-below(xs) {
    overflow-x: auto;
    flex-wrap: nowrap;
    padding: 0 2%;
  }
}

.container {
  @include respond-below(md) {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.text-right {
  @include respond-below(sm) {
    text-align: left !important;
  }
}

.pst-absolute-130px {
  @include respond-below(sm) {
    position: absolute;
    left: 130px;
  }
}
.pst-absolute-160px {
  @include respond-below(sm) {
    position: absolute;
    left: 212px;
  }
  @include respond-below(xs) {
    position: absolute;
    left: 160px;
  }
}
.pst-absolute-125px {
  @include respond-below(xs) {
    position: absolute;
    left: 125px;
  }
}
.center {
  @include respond-below(sm) {
    margin: 0 auto;
  }
}

.fc-toolbar-title {
  @include respond-below(xs) {
    text-align: center !important;
  }
}
.fc-daygrid-event-dot {
  @include respond-below(xs) {
    margin: 0px 1px !important;
  }
}
.fc-col-header-cell-cushion {
  @include respond-below(xs) {
    padding: 0 !important;
  }
}

.fc-button {
  @include respond-below(xs) {
    padding: 0.7em 0.54em !important;
    font-size: 0.8em !important;
    margin-top: 0.55em !important;
  }
}
.fc-daygrid-event-harness {
  overflow: hidden;
}
.fc-daygrid-day-bottom {
  @include respond-below(xs) {
    text-align: center !important;
  }
}

.fc-event-future,
.fc-event-end {
  @include respond-below(xs) {
    font-size: 0.7rem !important;
  }
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  background-color: $primary;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-shadow: 9px -2px 5px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 9px -2px 5px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 9px -2px 5px -11px rgba(0, 0, 0, 0.75);
  .btn {
    font-size: 1.3rem;
    white-space: nowrap;
  }
  a {
    text-decoration: none;
    font-size: 25px;
    color: $primary;
    display: block;
    transition: 0.3s;
    &:hover {
      color: $primary;
    }
  }
  .closebtn {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 1.2rem;
    i {
      color: $primary;
    }
  }
}
#nav_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.userdetails {
  white-space: nowrap;
  min-width: 60vw;
}
.baseContainer {
  @include respond-below(sm) {
    padding-left: 0;
    padding-right: 0;
    margin-top: 50px;
  }
}
.back {
  &.btn {
    @include respond-below(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.text-primary-color {
  color: $primary;
}
.wrapAnywhere {
  overflow-wrap: anywhere !important;
  white-space: normal;
}
.required:after {
  content: " *";
  color: rgb(174, 9, 9);
}
.w-unset {
  width: unset !important;
}
.fc-more-popover {
  max-height: 60vh;
  overflow-y: auto;
}

.clear {
  position: absolute;
  right: -10px;
  top: 7px;
  cursor: pointer;
  z-index: 90;
}

.lab-request-header,
.lab-request-header-space,
.page-header,
.page-header-space {
  // height: 200px;
  // padding-top: 50px;
  // background: greenyellow;
}

.lab-request-header,
.page-header {
  // border: 1px solid black;
  position: fixed;
  top: 0;
  width: 100%;
  // background: greenyellow;
}

.lab-request-footer,
.lab-request-footer-space,
.page-footer,
.page-footer-space {
  height: 120px;
}

.lab-request-footer,
.page-footer {
  // border-top: 1px solid black;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.page-footer {
  // display: flex;
  // justify-content: center;
  // align-items: flex-end;
  // background: hotpink;
  border-top: 1px solid black;
  padding-top: 5px;
  .footer-p {
    font-size: 1.1rem;
    margin-bottom: 0 !important;
    text-align: center;
  }
}
.print-title {
  text-align: center;
}

.page-table-cell {
  margin-top: 50px;
  // border: 1px solid black;
}

.page {
  page-break-after: always;
}

.lab-request-table {
  margin-top: 20px;
  td {
    // border: 1px solid blue;
  }
  width: 100%;
  // border: 1px solid black;
  vertical-align: top !important;
  .lab-request-tbody {
    vertical-align: top !important;
    tr {
      vertical-align: top !important;
      td {
        vertical-align: top !important;
      }
    }
  }
}

.printLogo {
  width: 150px;
}

.patient-record-logo {
  position: absolute;
  margin-top: -150px;
}

.print {
  display: none;
}

.print-table {
  display: none;
}

.page-table-cell {
  margin-top: 50px;
  // border: 1px solid black;
}

@media print {
  * {
    overflow: visible !important;
  }
  div[class^="Snackbar_snackbar-wrapper"] {
    display: none;
  }

  .patient-name-print-mode-link {
    text-decoration: none !important;
    border-bottom: none !important;
  }

  .patient-name-print-mode-btn {
    color: #212529;
  }

  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  body {
    margin: 0;
  }

  @page {
    size: A4;
    margin: 10mm !important;
  }
  .print-table {
    display: inline-table;
    width: 100%;
    height: 100%;
  }
  .print {
    display: block;
  }
  .modal-backdrop,
  .navigation_conainer {
    display: none !important;
  }
  .modal {
    display: none !important;
  }

  .information {
    h6 {
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 2rem;
    }
  }
}

.patient-date-container {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  width: 50%;
}

.patient-signature-container {
  display: flex;
  justify-content: flex-start;
  font-size: 1.5rem;
}

.encounter-print-patient-date {
  position: absolute;
  right: 170px;
  font-size: 1.3rem;
}

.text-field-line-date {
  border-bottom: 1px solid black;
  width: 300px;
  // flex: 1 auto;
}

.text-field-line-signature {
  border-bottom: 1px solid black;
  width: 300px;
}

.encounter-print-patient-name,
.encounter-print-patient-id {
  text-align: center;
  // margin-bottom: -37px;
  margin-top: 5px;
  font-size: 1.3rem;
  position: absolute;
  left: 400px;
}

.patient-name-container,
.patient-id-container {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  // margin-top: 30px;
}

.patient-total-container {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  // margin-top: 30px;
}

.encounter-print-patient-total {
  text-align: center;
  // margin-bottom: -37px;
  // margin-top: 5px;
  font-size: 1.3rem;
  position: absolute;
  left: 300px;
}

.text-field-line {
  border-bottom: 1px solid black;
  flex: 1 auto;
}

.payment-cont {
  position: relative;
}
.payment-number {
  color: red;
  font-size: 1.5rem;
  left: 100px;
  position: absolute;
}

.services-check {
  border: 1px solid black;
  width: 15px;
  min-width: 15px;
  height: 15px;
  // margin-right: 5px;
}

.services-table {
  width: 100%;
  margin-top: 50px;
  th {
    padding: 10px;
    text-align: center;
    font-size: 1.2rem;
  }
  td {
    padding: 20px;
    vertical-align: middle;
    text-align: center;
    font-size: 1.3rem;
    p {
      margin-bottom: 0;
    }
  }
  &.prescription_table {
    td,
    th {
      text-align: left;
      padding: 20px;
    }
  }
}
.iconCell {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appointment-detail-info {
  @include respond-below(xs) {
    margin-top: 130px;
  }
  @include respond-between(xs, sm) {
    margin-top: 50px;
  }
  @include respond-between(sm, md) {
    margin-top: 50px;
  }
  @include respond-above(md) {
    margin-top: 30px;
  }
}

.app-detail-first-line {
  @include respond-below(md) {
    flex-direction: column-reverse;
  }
}

.calendar-container {
  position: relative;

  .calendar-overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }
}

.paginationContainer {
  display: flex;
  justify-content: center;
  .pagination {
    flex-wrap: wrap;
  }
}

.red {
  color: red;
}
.information * {
  white-space: pre-line;
}

.page-link {
  line-height: 1.5rem !important;
  &:active {
    border: none;
  }
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__month-read-view--selected-month,
.react-datepicker__year-read-view--selected-year,
.react-datepicker__day-name {
  color: #fff !important;
}
.medicationContainer {
  padding: 0.45rem 0.75rem;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  html,
  body {
    height: 80%;
  }
}
.disabled-date {
  pointer-events: none;
  opacity: 0.5;
}

.underline-date {
  width: 60%;
  border-bottom: 1px solid;
  text-align: center;
}

.margin-top-10 {
  margin-top: 10rem;
}

.bg-grey {
  background-color: #0000000d;
}

.request-table {
  .table-responsive {
    overflow-x: unset;
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px; /* Set a specific width */
}

.accordion {
  .card {
    margin-bottom: 0;
  }
}

.dropdown-menu {
  .dropdown-item {
    color: black !important;
    font-size: 1rem;
  }
}
